import * as React from 'react';

import { ProfileFormContext } from '../../ProfileEditForm';
import { ACTIONS } from '../ProfileFormReducer';

// Rich Text Editor
import { convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import RichTextareaEditor from '../../../FormComponents/RichTextareaEditor';
import { initialEditorState } from '../../../FormComponents/RichTextareaEditor';

const BlankEducationHistoryItem = {
  school: { schoolname: "", qualification: "", desc: "", date: "" },
  editing: true
}

export default function EducationHistory() {
  const [state, dispatch] = React.useContext(ProfileFormContext);

  // Add new education history item
  const addEducationHistoryItem = () => {
    dispatch({
      type: ACTIONS.ADD_EDUCATION,
      payload: BlankEducationHistoryItem
    })
  }

  // Remove education history item by index
  const removeEducationHistoryItem = (index) => {
    dispatch({
      type: ACTIONS.REMOVE_EDUCATION,
      payload: index
    })
  }

  // Update education history item by id
  const updateEducationHistoryItem = (index, education) => {
    dispatch({
      type: ACTIONS.UPDATE_EDUCATION,
      payload: { index, education }
    })
  }

  // Edit Education history item by index
  const editEducationHistoryItem = (index) => {
    dispatch({
      type: ACTIONS.EDIT_EDUCATION,
      payload: index
    })
  }

  // Unedit Education history item by index
  const uneditEducationHistoryItem = (index) => {
    dispatch({
      type: ACTIONS.UNEDIT_EDUCATION,
      payload: index
    })
  }

  // Console log after state changes
  React.useEffect(() => {
    console.log(state.profile.education)
  }, [state.profile.education])

  return (
    <div key={state}>
      {/* Use Index */}
      {state.profile.education.map((education_history, index) => (
        <div key={index}
          className="shadow m-3 p-3 bg-light bg-gradient"
        >
          {/* Remove Education History Item */}
          {/* Align Top right */}
          <button
            type="button"
            className="btn btn-danger pull-right"
            onClick={() => removeEducationHistoryItem(index)}
          >
            <i className="fas fa-trash"></i>
          </button>

          {/* Edit Experience History Item */}
          {/* Align Top right */}
          {education_history.editing ? (
            <button
              type="button"
              className="btn btn-success pull-right me-1"
              onClick={() => uneditEducationHistoryItem(index)}
            >
              <i className="fas fa-check"></i>
            </button>)
            :
            (<button
              type="button"
              className="btn btn-warning pull-right me-1"
              onClick={() => editEducationHistoryItem(index)}
            >
              <i className="fas fa-edit"></i>
            </button>)}

          <EducationHistoryItem
            education_history={education_history}
            updateEducationHistoryItem={(eh) => updateEducationHistoryItem(index, eh)}
            editing={education_history.editing}
            key={index}
          />
        </div>
      ))}
      {/* Button to add new education history item */}
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary"
          onClick={addEducationHistoryItem}
        >
          Add Education
        </button>

      </div>
    </div>
  )
}

// Form for adding a new education_history
// { date: "", desc: "", title: "", employer: "" },

function EducationHistoryItem({ education_history, updateEducationHistoryItem, editing }) {
  // Create state object for education_history
  const [education_history_state, setEducationHistoryState] = React.useState(education_history)
  const { date, desc, qualification, schoolname } = education_history_state.school

  const [editorState, setEditorState] = React.useState(initialEditorState(desc))

  const onChangeEditor = editorState => setEditorState(editorState)

  // Update state object for education_history
  const updateEducationHistoryState = (e) => {
    setEducationHistoryState({
      ...education_history_state, school: {
        ...education_history_state.school,
        [`${e.target.name}`]: e.target.value
      }
    })
  }

  React.useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(rawContentState)

    setEducationHistoryState({
      ...education_history_state, school: {
        ...education_history_state.school,
        desc: markup
      }
    })
  }, [editorState])

  // Reset state object for education_history when education_history prop changes
  React.useEffect(() => {
    setEducationHistoryState(education_history)
  }, [education_history])

  // Update education_history in parent component when state changes
  React.useEffect(() => {
    console.log("Education History State Changed", education_history_state)
    updateEducationHistoryItem(education_history_state)
  }, [education_history_state])

  return (
    <>
      {editing ? (
        <div className="mt-4">
          <div className="form-group">
            <label htmlFor="date">Date</label>
            <input
              type="text"
              className="form-control"
              id="date"
              name="date"
              value={date}
              onChange={updateEducationHistoryState}
            />
          </div>
          <div className="form-group">
            <label htmlFor="title">Qualification</label>
            <input
              type="text"
              className="form-control"
              id="qualification"
              name="qualification"
              value={qualification}
              onChange={updateEducationHistoryState}
            />
          </div>
          <div className="form-group">
            <label htmlFor="employer">School Name</label>
            <input
              type="text"
              className="form-control"
              id="schoolname"
              name="schoolname"
              value={schoolname}
              onChange={updateEducationHistoryState}
            />
          </div>
          <div className="form-group">
            <label htmlFor="desc">Description</label>
            <RichTextareaEditor
              editorState={editorState}
              onChangeDescription={onChangeEditor} />
          </div>
        </div>
      ) : (
        <EducationPreview education_history={education_history_state} />
      )}
    </>
  )
}

const EducationPreview = ({ education_history }) => {
  const { date, desc, qualification, schoolname } = education_history.school

  return (
    <div>
      <h5><strong>{qualification}</strong></h5>
      <h6>{schoolname}</h6>
      <h6>{date}</h6>
      <div dangerouslySetInnerHTML={{ __html: desc }} />
    </div>
  )
}
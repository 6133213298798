import React, { useState, useRef, useEffect } from 'react';
import { useStore } from '@nanostores/react';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';
import SBProvider from '@sendbird/uikit-react/SendbirdProvider';
import SBConversation from '@sendbird/uikit-react/GroupChannel';
import SBChannelList from '@sendbird/uikit-react/GroupChannelList';
import SBChannelSettings from '@sendbird/uikit-react/ChannelSettings';
import '@sendbird/uikit-react/dist/index.css';
import { SBChatShow, SBChatUnread } from './SBChatStore';

export default function SBChatModal({ avatar_url, nickname, profile_id, app_id }) {
  const [showSettings, setShowSettings] = useState(false);
  const [currentChannelUrl, setCurrentChannelUrl] = useState('');
  const show = useStore(SBChatShow);
  const [size, setSize] = useState({ width: 700, height: 500 });
  const [channelListWidth, setChannelListWidth] = useState(180);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: window.innerWidth - 720, y: window.innerHeight - 520 });
  const dragRef = useRef(null);

  const setShow = () => {
    SBChatShow.set(!show);
  };

  const onResize = (event, { size }) => {
    setSize({ width: size.width, height: size.height });
  };

  const onChannelListResize = (event, { size }) => {
    setChannelListWidth(size.width);
  };

  const onDragStart = (e) => {
    setIsDragging(true);
    const startX = e.clientX - position.x;
    const startY = e.clientY - position.y;

    const onDrag = (e) => {
      setPosition({
        x: e.clientX - startX,
        y: e.clientY - startY,
      });
    };

    const onDragEnd = () => {
      setIsDragging(false);
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', onDragEnd);
    };

    document.addEventListener('mousemove', onDrag);
    document.addEventListener('mouseup', onDragEnd);
  };

  useEffect(() => {
    const handleResize = () => {
      setPosition((prev) => ({
        x: Math.min(prev.x, window.innerWidth - size.width),
        y: Math.min(prev.y, window.innerHeight - size.height),
      }));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [size]);

  if (!show) return null;

  return (
    <Resizable
      width={size.width}
      height={size.height}
      onResize={onResize}
      minConstraints={[350, 400]}
      maxConstraints={[1000, 800]}
      handle={<div className="custom-handle" />}
    >
      <div
        style={{
          position: 'fixed',
          width: `${size.width}px`,
          height: `${size.height}px`,
          bottom: `${window.innerHeight - position.y - size.height}px`,
          right: `${window.innerWidth - position.x - size.width}px`,
          backgroundColor: 'white',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          borderRadius: '8px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          ref={dragRef}
          onMouseDown={onDragStart}
          style={{
            padding: '10px',
            backgroundColor: '#176fc9',
            color: 'white',
            cursor: 'move',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h3 style={{ margin: 0 }}>Messages</h3>
          <button onClick={setShow} style={{ background: 'none', border: 'none', fontSize: '20px', cursor: 'pointer', color: 'white' }}>
            &times;
          </button>
        </div>
        <div style={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
          <Resizable
            width={channelListWidth}
            height={size.height - 40}
            onResize={onChannelListResize}
            axis="x"
            minConstraints={[120, 100]}
            maxConstraints={[300, 800]}
            handle={<div className="custom-handle-vertical" />}
          >
            <div
              className="custom-channel-list"
              style={{
                width: `${channelListWidth}px`,
                height: '100%',
                borderRight: '1px solid #e0e0e0',
                overflow: 'auto'
              }}>
              <SBChannelList
                selectedChannelUrl={currentChannelUrl}
                onChannelSelect={(channel) => setCurrentChannelUrl(channel?.url)}
                renderHeader={() => (
                  <SBChatChannelListHeader
                    avatar_url={avatar_url}
                    nickname={nickname}
                  />
                )}
                renderChannelPreview={({ channel }) => (
                  <div style={{
                    padding: '10px',
                    borderBottom: '1px solid #e0e0e0',
                    cursor: 'pointer',
                    background: channel.url === currentChannelUrl ? '#f0f0f0' : 'transparent',
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}>
                    <img
                      src={channel.coverUrl || 'https://via.placeholder.com/40'}
                      alt={channel.name}
                      style={{
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%',
                        marginRight: '8px',
                        flexShrink: 0,
                      }}
                    />
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <div style={{
                        fontWeight: 'bold',
                        marginBottom: '2px',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        fontSize: '12px',
                      }}>
                        {channel.name || 'Unnamed Channel'}
                      </div>
                      <div style={{
                        fontSize: '11px',
                        color: '#666',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        lineHeight: '1.2em',
                        maxHeight: '2.4em',
                      }}>
                        {channel.lastMessage ? channel.lastMessage.message : 'No messages yet'}
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
          </Resizable>
          <div style={{ flex: 1, overflow: 'auto' }}>
            {currentChannelUrl ? (
              <SBConversation
                channelUrl={currentChannelUrl}
                onChatHeaderActionClick={() => setShowSettings(true)}
              />
            ) : (
              <div style={{ padding: '20px', textAlign: 'center' }}>
                Select a channel or start a new conversation
              </div>
            )}
          </div>
          {showSettings && (
            <div style={{ width: '250px', borderLeft: '1px solid #e0e0e0', overflow: 'auto' }}>
              <SBChannelSettings
                channelUrl={currentChannelUrl}
                onCloseClick={() => setShowSettings(false)}
              />
            </div>
          )}
        </div>
      </div>
    </Resizable>
  );
}

function SBChatChannelListHeader({ avatar_url, nickname }) {
  return (
    <div style={{
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #e0e0e0',
      backgroundColor: '#f8f9fa',
    }}>
      <img
        src={avatar_url}
        alt="User avatar"
        style={{
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          marginRight: '8px',
          objectFit: 'cover',
        }}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
      }}>
        <span style={{
          fontSize: '14px',
          fontWeight: '600',
          color: '#212529',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
          {nickname}
        </span>
        <span style={{
          fontSize: '11px',
          color: '#6c757d',
        }}>
          My Account
        </span>
      </div>
    </div>
  );
}
import React, { useState } from 'react';

import SBProvider from '@sendbird/uikit-react/SendbirdProvider'
import '@sendbird/uikit-react/dist/index.css'

import SBChatModal from './SBChatModal'

import { useStore } from '@nanostores/react'
import { SBChatShow, SBChatUnread } from './SBChatStore'

export default function SBChat({
  avatar_url,
  nickname,
  profile_id,
  app_id
}) {

  const unread = useStore(SBChatUnread)
  const show = useStore(SBChatShow)

  const setShow = () => {
    SBChatShow.set(true)
  }

  return (
    <>
      {/* Chat Bubble Button w/ unread count if > 0 */}
      <a
        onClick={setShow}
        className="nav-link"
        style={{ color: '#085ec8' }}
      >
        {/* Chat Bubble Icon - FontAwesome */}
        <i className="fas fa-comment-alt" style={{ fontSize: 15 }}></i>
        {!unread && (<>&nbsp;Chat</>)}
        {/* Unread Count - Displayed if > 0 */}
        {unread > 0 && <span className="unread">{unread}</span>}
      </a>

      {show && (
        <SBProvider appId={app_id} userId={profile_id} nickname={nickname}>
          <SBChatModal
            show={show}
            onHide={() => console.log('onHide')}
            avatar_url={avatar_url}
            nickname={nickname}
            profile_id={profile_id}
            app_id={app_id}
          />
        </SBProvider>
      )}
    </>
  )
}
import { string } from "prop-types"
import * as React from "react"

export interface Post {
  link: string
  _embedded: object
  [prop: string]: any
}

export interface BlogPostProps {
  post: Post
}

function decodeEntities(s) {
  var str,
    temp = document.createElement("p")
  temp.innerHTML = s
  str = temp.textContent || temp.innerText
  temp = null
  return str
}

export default function BlogPost({ post }: BlogPostProps) {
  const imageUrl: string =
    post._embedded["wp:featuredmedia"][0].media_details.sizes.full
      .source_url

  return (
    <div className="col-md-4 col-8 px-0 px-3 pb-3">
      <a className="blog-img" href={post.link} target="_blank">
        <img className=" img-fluid" src={imageUrl} alt="" />
      </a>
      <div className="hover-icon"></div>
      <a className="text-decoration-none pt-4 mt-2 " href="#" target="_blank">
        <h4 className="blog-post-title">
          {decodeEntities(post.title.rendered)}
        </h4>
      </a>
      {/* <a href={post.link} className="btn read-more-link" target="_blank">
        Read More
      </a> */}
    </div>
  )
}

import * as React from 'react';

// import { pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js;"

// If Environment Variable rails_env is production
// import { Document, Page } from 'react-pdf';

// If Environment Variable rails_env is production
var Document = require('react-pdf/dist/esm/entry.webpack').Document;
var Page = require('react-pdf/dist/esm/entry.webpack').Page;

export default function Resume({ url }) {
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [error, setError] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setLoaded(true);
    setNumPages(numPages);
  }

  // Ensure http: is at beginning of url
  if (url && url.slice(0, 6) !== 'https:') {
    url = 'https:' + url;
  }
  return <>
    {!error &&
      <div className={`${loaded ? 'card shadow-sm profile-card-design mt-4' : ''}`} style={{
        opacity: loaded ? 1 : 0,
        height: loaded ? 'auto' : 0,
      }}>
        <div className="card-header profile-cards-head"><i className="fas fa-certificate"></i> Resume</div>
        <div className="text-center my-3">
          {/* Link to PDF with magnify glass*/}
          <div className="text-center pt-2 pb-4">
            <a href={url} target="_blank" rel="noopener noreferrer" className="text-decoration-none"><i className="fas fa-search-plus"></i> Click to Enlarge PDF</a>
          </div>
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={() => setError(true)}
            onSourceError={() => setError(true)}
          >
            <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
          </Document>
          {numPages > 1 &&
            <>
              <p className="mt-3">Page {pageNumber} of {numPages}</p>
              {/* Next and previous page buttons */}
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary mx-2"
                  disabled={pageNumber <= 1}
                  onClick={() => setPageNumber(pageNumber - 1)}
                >
                  Previous
                </button>
                <button
                  className="btn btn-primary mx-2"
                  disabled={pageNumber >= numPages}
                  onClick={() => setPageNumber(pageNumber + 1)}
                >
                  Next
                </button>
              </div>
            </>}
        </div>
      </div>
    }
  </>;
}
import React from "react"
import DraftTextArea from "./DraftTextArea"
import TextField from "./TextField"

const ProfileExperience = (props) => {
  const [state, setState] = React.useState({ experience: props.experience })
  const { update } = props
  const [, forceUpdate] = React.useState(0)

  const addExperience = () => {
    setState({
      experience: [
        ...state.experience,
        { date: "", desc: "", title: "", employer: "" },
      ],
    })
  }

  const moveUpFun = (i) => {
    const experience = move(state.experience, i, i - 1)
    setState({ experience })
  }

  const moveDownFun = (i) => {
    if (i === state.experience.length - 1) {
      return false
    }
    const experience = move(state.experience, i, i + 1)
    setState({ experience })
  }

  const deleteItem = (index) => {
    const temp_experience = state.experience
    const experienceSpliced = temp_experience.splice(index, 1)
    setState({ experience: temp_experience })
  }

  const updateItem = (key, value, index) => {
    const experiences = state.experience
    const item = experiences[index]
    item[key] = value
    experiences[index] = item
    setState({ experience: experiences })
  }

  React.useEffect(() => {
    if (update) update(state.experience)
  }, [state])

  return (
    <div className="form-inside">
      {state.experience.map((exp, index) => (
        <Experience
          index={`${index}-${exp.title}`}
          {...exp}
          deleteItemFun={() => deleteItem(index)}
          moveUpFun={() => moveUpFun(index)}
          moveDownFun={() => moveDownFun(index)}
          updateEmployer={(e) => updateItem("employer", e, index)}
          updateTitle={(e) => updateItem("title", e, index)}
          updateDate={(e) => updateItem("date", e, index)}
          updateDesc={(e) => updateItem("desc", e, index)}
        />
      ))}
      <a
        onClick={() => addExperience()}
        className="btn button my-2 add-box"
      >
        <i className="fa fa-plus-circle"></i> Add Experience
      </a>
    </div>
  )
}

const Experience = ({
  date,
  desc,
  title,
  employer,
  index,
  deleteItemFun,
  moveUpFun,
  moveDownFun,
  updateEmployer,
  updateTitle,
  updateDate,
  updateDesc,
}) => {
  return (
    <div className="form boxed experience-box experience-background px-3 py-3 my-3">
      <div className="d-flex justify-content-end align-items-end mb-2">
        <a
          className="btn adjust-order-btn close-form mx-2"
          onClick={() => moveDownFun()}
        >
          <i className="fa fa-arrow-down"></i>
        </a>
        <a
          className="btn adjust-order-btn close-form mx-2"
          onClick={() => moveUpFun()}
        >
          <i className="fa fa-arrow-up"></i>
        </a>
        <a
          className="btn adjust-order-btn close-form mx-2"
          onClick={() => deleteItemFun()}
        >
          <i className="fa fa-close"></i>
        </a>
      </div>

      <TextField
        name="profile[experience[]job][employer]"
        id="profile_experience_job_employer"
        value={employer}
        updateFunc={(e) => updateEmployer(e)}
        placeholder="Employer"
      />
      <TextField
        name="profile[experience[]job][title]"
        id="profile_experience_job_title"
        value={title}
        updateFunc={(e) => updateTitle(e)}
        placeholder="Job Title"
      />
      <TextField
        name="profile[experience[]job][date]"
        id="profile_experience_job_date"
        value={date}
        updateFunc={(e) => updateDate(e)}
        placeholder="Date"
      />
      <DraftTextArea
        key={`${index}-desc`}
        updateFunc={(e) => updateDesc(e)}
        containerclassName={""}
        name="profile[experience[]job][desc]"
        id="profile_experience_job_desc"
        rich={true}
        value={desc}
      />
    </div>
  )
}

function move(arr, old_index, new_index) {
  while (old_index < 0) {
    old_index += arr.length
  }
  while (new_index < 0) {
    new_index += arr.length
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length
    while (k-- + 1) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr
}

export default ProfileExperience

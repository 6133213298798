/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// console.log('Hello World from Webpacker');
// // Support component names relative to this directory:
// var componentRequireContext = require.context("components", true);
// var ReactRailsUJS = require("react_ujs");
// ReactRailsUJS.useContext(componentRequireContext);
import ReactOnRails from 'react-on-rails';

// Dashboard
import HelpIconWithTooltip from '../components/HelpIconWithTooltip';

// Homepage
import JobSpotlight from '../components/Job/JobSpotlight';
import BlogPosts from '../components/BlogPosts/BlogPosts';
import Testimonials from '../components/Testimonials/Testimonials';

// Temp Rating
import TempRating from '../components/ratings/TempRating';

// Get Started Billing Pages, Plans Pages
import PlanDescription from '../components/Billing/Products/PlanDescription';
import GetStarted from '../components/Billing/Products/GetStarted';
import ProductCalculator from '../components/Billing/Products/ProductCalculator';
import Plans from '../components/Plans';

import UpdateSubscription from '../components/Billing/UpdateSubscription';
import UpdateSubscriptionButton from '../components/Subscriptions/UpdateSubscriptionButton';

// Profile
import Profiles from '../components/Profiles';
import ProfileForm from '../components/Profile/ProfileForm';
import ProfileEditForm from '../components/Profile/ProfileEditForm';
import ProfileBadges from '../components/Profile/ProfileBadges';
import Resume from '../components/Profile/Resume';

import AddFavoriteCandidate from '../components/Favorites/AddFavoriteCandidate';
import RequestProfessional from '../components/RequestProfessional';
import ResumeRequest from '../components/Profile/ResumeRequest';
import ProfileNotes from '../components/Notes/ProfileNotes';

// Analytics
import JobAnalytics from '../components/Analytics/JobAnalytics';

import ApplicationStatus from '../components/ApplicationStatus';
import QuickApply from '../components/Profile/QuickApply';
import ProfileList from '../components/Profile/ProfileList';
import ModifyMembershipButton from '../components/Account/ModifyMembershipButton';

import SubFAQ from '../components/SubFAQ';
import FavoriteProfiles from '../components/Favorites/FavoriteProfiles';
import AddBlockCandidate from '../components/Favorites/AddBlockCandidate';
import Jobs from '../components/Jobs';

import Manage from '../components/ManageJobs/Manage';
import ManageApplications from '../components/Applications/ManageApplications';
import ManageApplicants from '../components/Applicants/ManageApplicants';
import JobUpgrade from '../components/Job/JobUpgrade';
import Job from '../components/Job/Job';
import PracticeFavoriteButton from '../components/PracticeFavoriteButton';
import Locations from '../components/Locations/Locations';
import JobHome from '../components/job_search/JobHome';
import JobDescription from '../components/JobDescription';
import TempProfile from '../components/TempProfile';
import StaffingProduct from '../components/Billing/StaffingProduct';
import AutocompleteAddress from '../components/FormComponents/AutocompleteAddress';
import AutocompleteAddressOld from '../components/FormComponents/AutocompleteAddressOld';
import ProfilePhoto from '../components/ProfilePhoto';
import DraftTextArea from '../components/FormComponents/DraftTextArea';
import ProfileExperience from '../components/FormComponents/ProfileExperience';
import ProfileEducation from '../components/FormComponents/ProfileEducation';
import Form from '../components/Jobs/Form';
import LocationDescription from '../components/Locations/LocationDescription';
import Schedule from '../components/Schedule';
import CardForm from '../components/Billing/CardForm';
import OrderSummary from '../components/Billing/Products/OrderSummary';
import JobAccepted from '../components/Jobs/TempRequests/JobAccepted';
import JobFilled from '../components/Jobs/TempRequests/JobFilled';
import JobRequested from '../components/Jobs/TempRequests/JobRequested';
import JobSearchJob from '../components/job_search/Job';
import ProfileSearchProfileList from '../components/profiles_search/ProfileList';

import BillingPlansPlans from '../components/Billing/Plans/Plans';
import BillingProductsProducts from '../components/Billing/Products/Products';

// Not sure if I need these yet:
import SendMessage from '../components/SendMessage';
import RecruiterServiceCart from '../components/Pricing/RecruiterServiceCart/RecruiterServiceCart';

import SBChat from '../components/SBChat/SBChat';

ReactOnRails.register({
    HelpIconWithTooltip,
    JobSpotlight,
    BlogPosts,
    Testimonials,
    TempRating,
    PlanDescription,
    GetStarted,
    Plans,
    ProductCalculator,
    SendMessage,
    RequestProfessional,
    Profiles,
    ProfileForm,
    ProfileEditForm,
    AddFavoriteCandidate,
    ProfileBadges,
    JobAnalytics,
    UpdateSubscription,
    UpdateSubscriptionButton,
    ResumeRequest,
    Resume,
    ProfileNotes,
    ApplicationStatus,
    QuickApply,
    ProfileList,
    ModifyMembershipButton,
    SubFAQ,
    FavoriteProfiles,
    AddBlockCandidate,
    Jobs,
    Manage,
    ManageApplications,
    JobUpgrade,
    Job,
    PracticeFavoriteButton,
    Locations,
    JobHome,
    JobDescription,
    TempProfile,
    ManageApplicants,
    StaffingProduct,
    AutocompleteAddressOld,
    ProfilePhoto,
    DraftTextArea,
    ProfileExperience,
    ProfileEducation,
    Form,
    AutocompleteAddress,
    LocationDescription,
    Schedule,
    CardForm,
    OrderSummary,
    RecruiterServiceCart,
    JobAccepted,
    JobFilled,
    JobRequested,
    JobSearchJob,
    ProfileSearchProfileList,
    BillingPlansPlans,
    BillingProductsProducts,
    SBChat
});

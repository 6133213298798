import * as React from "react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import "react-tabs/style/react-tabs.css"

// Profile Form Components
import GeneralInfo from "./ProfileFormComponents/GeneralInfo"
import Experience from "./ProfileFormComponents/Experience"
import Availability from "./ProfileFormComponents/Availability"
import Documents from "./ProfileFormComponents/Documents"

// Bootstrap Progress
import ProgressBar from "react-bootstrap/ProgressBar"

// Profile Form Reducer
import { ACTIONS, ProfileFormReducer } from "./ProfileFormComponents/ProfileFormReducer"

// Submit Profile Data to API /profiles/:id.json
const submitProfile = (profile, dispatch, authenticity_token) => {

  const profileData = {
    first_name: profile.first_name,
    last_name: profile.last_name,
    phone: profile.phone,
    phone_ext: profile.phone_ext,
    education: profile.education,
    experience: profile.experience,
    years_experience: profile.years_experience,
    background: profile.background,
    street_address: profile.street_address,
    street_address_2: profile.street_address_2,
    city: profile.city,
    state: profile.state,
    zip: profile.zip,
    position_type_ids: profile.position_type_ids,
    skill_ids: profile.skill_ids,
    job_type_ids: profile.job_type_ids,
    hourly_rate: profile.hourly_rate,
    maximum_traveling_distance: profile.maximum_traveling_distance,
  }

  const url = `/profiles/${profile.id}.json`
  const method = "put"
  const data = { profile: profileData }

  // FORM_SUBMIT_START
  dispatch({ type: ACTIONS.FORM_SUBMIT_START })

  fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": authenticity_token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        dispatch({ type: ACTIONS.FORM_SUBMIT_SUCCESS })
        return response.json()
      }
      // throw new Error("Network response was not ok.")
    })
    .then((response) => {
      // Remove .json from response.api_url
      const api_url = response.api_url.replace(".json", "")
      window.location.href = api_url
    })
    .catch((error) => {
      dispatch({ type: ACTIONS.FORM_SUBMIT_FAILURE })
    })
}

function initialState(profile, job_types, hiring_types, skills, skill_categories, authenticity_token) {
  const education = profile.education || []
  const experience = profile.experience || []

  // if education's first element has a key named "editing", delete the first element
  if (education.length > 0 && education[0].editing) education.shift()
  if (experience.length > 0 && experience[0].editing) experience.shift()

  experience.forEach((exp) => {
    delete exp.editing
  })

  education.forEach((edu) => {
    delete edu.editing
  })

  // If any objects with no keys, delete them
  education.forEach((edu) => {
    if (Object.keys(edu).length == 0) {
      education.splice(education.indexOf(edu), 1)
    }
  })

  experience.forEach((exp) => {
    if (Object.keys(exp).length == 0) {
      experience.splice(experience.indexOf(exp), 1)
    }
  })

  return {
    options: {
      job_types,
      hiring_types
    },
    authenticity_token,
    skills,
    skill_categories,
    processing: false,
    error: false,
    experience_selected: false,
    experience: false,
    tabIndex: 0,
    profile: {
      first_name: "",
      last_name: "",
      phone: "",
      zip: "",
      // resume: "",
      position_type_ids: profile.position_types.map((p) => p.id) || [],
      skill_ids: profile.skills.map((s) => s.id) || [],
      job_type_ids: profile.job_types.map((j) => j.id) || [],
      ...profile,
      experience,
      education,
    },
    validation_errors: {
      first_name: false,
      last_name: false,
      phone: false,
      zip: false,
      positions: false,
      availability: false,
      experience_or_resume: false,
    },
  }
}

// Check State Validations
export const checkValidations = (state) => {
  var validation_errors = {
    first_name: false,
    last_name: false,
    phone: false,
    zip: false,
    positions: false,
    availability: false,
    experience_or_resume: false,
  }

  if (state.profile.first_name == "") {
    validation_errors.first_name = true
  }

  if (state.profile.last_name == "") {
    validation_errors.last_name = true
  }

  if (state.profile.phone == "") {
    validation_errors.phone = true
  }

  if (state.profile.zip == "") {
    validation_errors.zip = true
  }

  if (state.profile.position_type_ids.length == 0) {
    validation_errors.positions = true
  }

  if (state.profile.job_type_ids.length == 0) {
    validation_errors.availability = true
  }

  if (state.profile.experience.length == 0 && state.profile.resume == "") {
    validation_errors.experience_or_resume = true
  }

  return validation_errors
}

// Create a Context Provider
export const ProfileFormContext = React.createContext([])

export default function ProfileForm({
  skills,
  skill_categories,
  profile,
  job_types,
  hiring_types,
  authenticity_token
}) {
  const [state, dispatch] = React.useReducer(
    ProfileFormReducer,
    initialState(profile, job_types, hiring_types, skills, skill_categories, authenticity_token)
  )

  // var Spinner = require("react-spinkit")

  // Next Tab
  const nextTab = () => {
    const element = document.getElementById("profileeditformcontainer")
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" })
    }

    // Delay
    setTimeout(() => {
      dispatch({ type: ACTIONS.SET_TAB_INDEX, payload: state.tabIndex + 1 })
    }, 200)
  }

  // Previous Tab
  const previousTab = () => {
    dispatch({ type: ACTIONS.SET_TAB_INDEX, payload: state.tabIndex - 1 })
  }

  // Equal Flex grow
  const tabStyles = "react-tabs__tab text-center flex-grow-1"

  // Submit Form
  const submitForm = () => {
    submitProfile(state.profile, dispatch, authenticity_token)
  }

  React.useEffect(() => {
    const element = document.getElementById("profileeditformcontainer")
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" })
    }

  }, [state.tabIndex])

  return (
    <ProfileFormContext.Provider value={[state, dispatch]}>
      <div id="profileeditformcontainer">
        <ProfileCompleteness profile={state.profile} />
      </div>

      {/* Loading */}
      {state.processing && (
        <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-75 z-50 flex items-center justify-center">
          {/* <Spinner name="ball-spin-fade-loader" color="blue" /> */}
          Processing...
        </div>
      )}

      {/* Error */}
      {state.error && (
        <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-75 z-50 flex items-center justify-center">
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline">There was an error submitting your profile.</span>
          </div>
        </div>
      )}

      {/* Form */}
      {!state.processing && !state.error && (
        <Tabs
          selectedIndex={state.tabIndex}
          onSelect={
            (index) => dispatch({ type: ACTIONS.SET_TAB_INDEX, payload: index })
          }>

          {/* Flex horizontal, equally spaced, equal grow */}
          {/* no gap */}
          <TabList className="d-flex justify-content-between gap-0">
            <Tab className={tabStyles}>
              <i className="fas fa-user mr-2"></i> {state.tabIndex == 0 && 'General Info'}
            </Tab>
            <Tab className={tabStyles}>
              <i className="fas fa-file mr-2"></i> {state.tabIndex == 1 && 'Documents'}
            </Tab>
            <Tab className={tabStyles}>
              <i className="fas fa-briefcase mr-2"></i> {state.tabIndex == 2 && 'Experience'}
            </Tab>
            <Tab className={tabStyles}>
              <i className="fas fa-calendar mr-2"></i> {state.tabIndex == 3 && 'Availability'}
            </Tab>
          </TabList>

          {/* General Information */}
          <TabPanel>
            <>
              <GeneralInfo />
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => nextTab()}
                  disabled={state.processing}
                >Continue</button>
              </div>
            </>
          </TabPanel>

          {/* Documents */}
          <TabPanel>
            <>
              <Documents />

              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  onClick={() => nextTab()}
                  disabled={state.processing}
                >Continue</button>
              </div>
            </>
          </TabPanel>

          {/* Positions */}
          <TabPanel>
            <>

              <Experience />
              <div className="d-flex justify-content-center mt-5">
                <button
                  className="btn btn-primary"
                  onClick={() => nextTab()}
                  disabled={state.processing}
                >Continue</button>
              </div>
            </>
          </TabPanel>

          {/* Availability */}
          <TabPanel>
            <>
              <Availability />


              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary"
                  // onClick={() => nextTab()}
                  onClick={() => submitForm()}
                  disabled={state.processing}
                >Submit</button>
              </div>
            </>
          </TabPanel>
        </Tabs>
      )}
    </ProfileFormContext.Provider>
  )
}

function ProfileCompleteness({ profile }) {
  const profileFields = check_fields(profile)

  let percentage = 100;

  // Loop through each profileFields
  // If the field.field is empty or nil, subtract field.value from percentage

  profileFields.forEach((field) => {
    // If Not Array
    if (!Array.isArray(field.field)) {
      if (field.field == "" || field.field == null || field.field == undefined) {
        percentage -= field.value
      }
    } else {
      // If Array
      if (field.field.length == 0) {
        percentage -= field.value
      }
    }
  })

  return (
    <div className="col-lg-12 my-5 contact-font bg-secondary rounded p-4">
      <div className="info-banner">
        <div className="info-content">
          <h2>Profile Completeness - {percentage}%</h2>
          <ProgressBar now={percentage} />
        </div>
        <p>A more complete profile will help you get more job offers.</p>
      </div>
    </div>
  )
}

function check_fields(profile) {
  return (
    [
      { field: profile.first_name, value: 5, name: 'First Name', section: '1' },
      { field: profile.last_name, value: 5, name: 'Last Name', section: '1' },
      { field: profile.phone, value: 5, name: 'Phone', section: '1' },
      { field: profile.street_address, value: 5, name: 'Street Address', section: '1' },
      { field: profile.city, value: 5, name: 'City', section: '1' },
      { field: profile.state, value: 5, name: 'State', section: '1' },
      { field: profile.zip, value: 5, name: 'Zip', section: '1' },

      { field: profile.avatar_url, value: 5, name: 'Profile Image', section: '1' },

      { field: profile.background, value: 5, name: 'About Me', section: '2' },
      { field: profile.job_types, value: 10, name: 'Position Availability', section: '2' },
      { field: profile.years_experience, value: 5, name: 'Years Experience', section: '2' },
      { field: profile.resume || profile.experience || profile.education || [], value: 15, name: 'Experience and Education', section: '2' },

      { field: profile.skills, value: 5, name: 'Specialty Experience', section: '3' },
      // { field: profile.specialty, value: 5, name: 'Specialty Experience', section: '3' },
      // { field: profile.special, value: 5, name: 'Special Skills', section: '3' },
      // { field: profile.software, value: 5, name: 'Software Experience', section: '3' },

      { field: profile.hourly_rate, value: 5, name: 'Hourly Rate', section: '4' },
      // { field: profile.temp_availabilities, value: 5, name: 'Temp Availability', path: '/availability' },
      { field: profile.position_types, value: 5, name: 'Hiring Availability', section: '4' },

      // { field: profile.profile_documents, value: 5, name: 'Licenses and Certifications', section: '5' }
    ]
  )
}
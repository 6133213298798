
// Reducer Action Types ACTIONS.SET_TAB_INDEX
export const ACTIONS = {
  SET_TAB_INDEX: "SET_TAB_INDEX",
  SET_PROCESSING: "SET_PROCESSING",
  SET_PROFILE: "SET_PROFILE",
  SET_VALIDATION_ERRORS: "SET_VALIDATION_ERRORS",

  // Form Values
  SET_FIELD: "SET_FIELD",
  SET_SKILLS: "SET_SKILLS",
  SET_JOB_TYPES: "SET_JOB_TYPES",

  // Experience
  ADD_EXPERIENCE: "ADD_EXPERIENCE",
  REMOVE_EXPERIENCE: "REMOVE_EXPERIENCE",
  UPDATE_EXPERIENCE: "UPDATE_EXPERIENCE",
  EDIT_EXPERIENCE: "EDIT_EXPERIENCE",
  UNEDIT_EXPERIENCE: "UNEDIT_EXPERIENCE",

  // Education
  ADD_EDUCATION: "ADD_EDUCATION",
  REMOVE_EDUCATION: "REMOVE_EDUCATION",
  UPDATE_EDUCATION: "UPDATE_EDUCATION",
  EDIT_EDUCATION: "EDIT_EDUCATION",
  UNEDIT_EDUCATION: "UNEDIT_EDUCATION",

  // Form Submit
  FORM_SUBMIT_START: "FORM_SUBMIT_START",
  FORM_SUBMIT_SUCCESS: "FORM_SUBMIT_SUCCESS",
  FORM_SUBMIT_FAILURE: "FORM_SUBMIT_FAILURE",
}

// Reducer
export const ProfileFormReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_TAB_INDEX:
      return { ...state, tabIndex: action.payload }
    case ACTIONS.SET_PROCESSING:
      return { ...state, processing: action.payload }
    case ACTIONS.SET_PROFILE:
      return { ...state, profile: action.payload }
    case ACTIONS.SET_VALIDATION_ERRORS:
      return { ...state, validation_errors: action.payload }

    // Form Values
    case ACTIONS.SET_FIELD:
      return {
        ...state, profile: {
          ...state.profile, ...action.payload
        }
      }

    // Add Experience
    case ACTIONS.ADD_EXPERIENCE:
      return {
        ...state,
        profile: {
          ...state.profile,
          experience: [...state.profile.experience, action.payload],
        },
      }

    // Remove Experience
    case ACTIONS.REMOVE_EXPERIENCE:
      const newExperience = [
        ...state.profile.experience.slice(0, action.payload),
        ...state.profile.experience.slice(action.payload + 1),
      ]

      return {
        ...state,
        profile: {
          ...state.profile,
          experience: newExperience,
        },
      }

    // Update Experience
    case ACTIONS.UPDATE_EXPERIENCE:
      return {
        ...state,
        profile: {
          ...state.profile,
          experience: state.profile.experience.map((exp, index) =>
            index === action.payload.index ? action.payload.experience : exp
          ),
        },
      }

    // Edit Experience
    case ACTIONS.EDIT_EXPERIENCE:
      return {
        ...state,
        profile: {
          ...state.profile,
          experience: state.profile.experience.map((exp, index) =>
            index === action.payload ? { ...exp, editing: true } : exp
          ),
        },
      }

    // Unedit Experience
    case ACTIONS.UNEDIT_EXPERIENCE:
      // Remove editing key from experience history item
      const experience = state.profile.experience[action.payload]
      delete experience.editing

      return {
        ...state,
        profile: {
          ...state.profile,
          experience: state.profile.experience.map((exp, index) =>
            index === action.payload ? experience : exp
          ),
        },
      }

    case ACTIONS.SET_SKILLS:
      return {
        ...state,
        profile: {
          ...state.profile,
          skills: action.payload,
          skill_ids: action.payload
        }
      }

    // SET_JOB_TYPES
    case ACTIONS.SET_JOB_TYPES:
      return {
        ...state,
        profile: {
          ...state.profile,
          job_type_ids: action.payload
        }
      }

    // Add Education
    case ACTIONS.ADD_EDUCATION:
      return {
        ...state,
        profile: {
          ...state.profile,
          education: [...state.profile.education, action.payload],
        },
      }

    // Remove Education
    case ACTIONS.REMOVE_EDUCATION:
      const newEducation = [
        ...state.profile.education.slice(0, action.payload),
        ...state.profile.education.slice(action.payload + 1),
      ]

      return {
        ...state,
        profile: {
          ...state.profile,
          education: newEducation,
        },
      }

    // Update Education
    case ACTIONS.UPDATE_EDUCATION:
      return {
        ...state,
        profile: {
          ...state.profile,
          education: state.profile.education.map((edu, index) =>
            index === action.payload.index ? action.payload.education : edu
          ),
        },
      }

    // Edit Education
    case ACTIONS.EDIT_EDUCATION:
      return {
        ...state,
        profile: {
          ...state.profile,
          education: state.profile.education.map((edu, index) =>
            index === action.payload ? { ...edu, editing: true } : edu
          ),
        },
      }

    // Unedit Education
    case ACTIONS.UNEDIT_EDUCATION:
      // Remove editing key from education history item
      const education = state.profile.education[action.payload]
      delete education.editing

      return {
        ...state,
        profile: {
          ...state.profile,
          education: state.profile.education.map((edu, index) =>
            index === action.payload ? education : edu
          ),
        },
      }

    // Form Submit
    case ACTIONS.FORM_SUBMIT_START:
      return { ...state, processing: true, error: false }
    case ACTIONS.FORM_SUBMIT_SUCCESS:
      return { ...state, processing: false, error: false }
    case ACTIONS.FORM_SUBMIT_FAILURE:
      return { ...state, processing: false, error: true }

    default:
      return state
  }
}
import * as React from 'react';

import { ProfileFormContext } from '../../ProfileEditForm';
import { ACTIONS } from '../ProfileFormReducer';

// Rich Text Editor
import { convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import RichTextareaEditor from '../../../FormComponents/RichTextareaEditor';
import { initialEditorState } from '../../../FormComponents/RichTextareaEditor';

const BlankExperienceHistoryItem = {
  job: { date: '', desc: '', title: '', employer: '' },
  editing: true
}

export default function ExperienceHistory() {
  const [state, dispatch] = React.useContext(ProfileFormContext);

  // Add new experience history item
  const addExperienceHistoryItem = () => {
    dispatch({
      type: ACTIONS.ADD_EXPERIENCE,
      payload: BlankExperienceHistoryItem
    })
  }

  // Remove experience history item by index
  const removeExperienceHistoryItem = (index) => {
    dispatch({
      type: ACTIONS.REMOVE_EXPERIENCE,
      payload: index
    })
  }

  // Edit experience history item by index
  const editExperienceHistoryItem = (index) => {
    dispatch({
      type: ACTIONS.EDIT_EXPERIENCE,
      payload: index
    })
  }

  // Unedit experience history item by index
  const uneditExperienceHistoryItem = (index) => {
    dispatch({
      type: ACTIONS.UNEDIT_EXPERIENCE,
      payload: index
    })
  }

  // Update experience history item by id
  const updateExperienceHistoryItem = (index, experience) => {
    dispatch({
      type: ACTIONS.UPDATE_EXPERIENCE,
      payload: { index, experience }
    })
  }

  // Console log after state changes
  React.useEffect(() => {
    console.log(state.profile.experience)
  }, [state.profile.experience])

  return (
    <div key={state}>
      {/* Use Index */}
      {state.profile.experience.map((experience_history, index) => (
        <div key={index}
          className="shadow m-3 p-3 bg-light bg-gradient"
        >
          {/* Remove Experience History Item */}
          {/* Align Top right */}
          <button
            type="button"
            className="btn btn-danger pull-right"
            onClick={() => removeExperienceHistoryItem(index)}
          >
            <i className="fas fa-trash"></i>
          </button>

          {/* Edit Experience History Item */}
          {/* Align Top right */}
          {experience_history.editing ? (
            <button
              type="button"
              className="btn btn-success pull-right me-1"
              onClick={() => uneditExperienceHistoryItem(index)}
            >
              <i className="fas fa-check"></i>
            </button>)
            :
            (<button
              type="button"
              className="btn btn-warning pull-right me-1"
              onClick={() => editExperienceHistoryItem(index)}
            >
              <i className="fas fa-edit"></i>
            </button>)}

          <ExperienceHistoryItem
            experience_history={experience_history}
            updateExperienceHistoryItem={
              (eh) => updateExperienceHistoryItem(index, eh)
            }
            editing={experience_history.editing}
            key={index}
          />
        </div>
      ))}
      {/* Button to add new experience history item */}
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary"
          onClick={addExperienceHistoryItem}
        >
          Add Experience
        </button>
      </div>
    </div>
  )
}

// Form for adding a new experience_history
// { date: "", desc: "", title: "", employer: "" },

function ExperienceHistoryItem({
  experience_history, updateExperienceHistoryItem, editing
}) {
  // Create state object for experience_history
  const [experience_history_state, setExperienceHistoryState] = React.useState(experience_history)
  const { date, desc, title, employer } = experience_history_state.job

  const [editorState, setEditorState] = React.useState(
    initialEditorState(desc)
  )

  const onChangeEditor = editorState => {
    setEditorState(editorState)
  }

  // Update state object for experience_history
  const updateExperienceHistoryState = (e) => {
    setExperienceHistoryState({
      ...experience_history_state, job: {
        ...experience_history_state.job,
        [`${e.target.name}`]: e.target.value
      }
    })
  }

  React.useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(rawContentState)

    setExperienceHistoryState({
      ...experience_history_state, job: {
        ...experience_history_state.job,
        desc: markup
      }
    })
  }, [editorState])

  // Reset state object for experience_history when experience_history prop changes
  React.useEffect(() => {
    setExperienceHistoryState(experience_history)
  }, [experience_history])

  // Update experience_history in parent component when state changes
  React.useEffect(() => {
    console.log("Experience History State Changed", experience_history_state)
    updateExperienceHistoryItem(experience_history_state)
  }, [experience_history_state])

  return (
    <>
      {
        editing ?
          (<div className="mt-4">
            <div className="form-group">
              <label htmlFor="date" className="form-label">Date</label>
              <input
                type="text"
                className="form-control"
                id="date"
                name="date"
                value={date}
                onChange={updateExperienceHistoryState}
              />
            </div>
            <div className="form-group">
              <label htmlFor="title" className="form-label">Title</label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={title}
                onChange={updateExperienceHistoryState}
              />
            </div>
            <div className="form-group">
              <label htmlFor="employer" className="form-label">Employer</label>
              <input
                type="text"
                className="form-control"
                id="employer"
                name="employer"
                value={employer}
                onChange={updateExperienceHistoryState}
              />
            </div>
            <div className="form-group">
              <label htmlFor="desc" className="form-label">Description</label>
              <RichTextareaEditor
                editorState={editorState}
                onChangeDescription={onChangeEditor} />
            </div>
          </div >)
          :
          <ExperiencePreview experience={experience_history_state} />
      }
    </>
  )
}

const ExperiencePreview = ({ experience }) => {
  return (
    <div>
      <h5><strong>{experience.job.title}</strong></h5>
      <h6>{experience.job.employer}</h6>
      <h6>{experience.job.date}</h6>
      <div dangerouslySetInnerHTML={{ __html: experience.job.desc }} />
    </div>
  )
}